<template>
    <div>
        <!-- <v-container fluid> -->
            <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">
                    Pilih Paket / Custom
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2">
                    Pilih Influencer
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">
                    Payment
                    </v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        <!-- </v-container> -->
        <br>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    props:['step'],
    setup() {
        return{
            e1:1
        }
    },
    mounted(){
        this.e1 = this.step;
        // console.log(this.e1)
    }
})
</script>
