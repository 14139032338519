<template>
  <div>
    <br>
    <!-- MOBILE -->
    <div v-if="isMobile()">
      <p class="body-1 text-center primary--text font-weight-bold">MAU PRODUK KAMU DI PROMOSIKAN OLEH BANYAK INFLUENCER?</p>
      <p class="text-center subtitle-2 black--text">VDC telah menyiapkan berbagai paket influencer. Pilih paket yang sesuai dengan kebutuhan brand saat ini</p>
      <v-row >
        <div class="col-sm-12">
          <v-card class="mt-0" color="text-center primary" @click="goPackageDetail('basic')">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <p class="white--text body-1 font-weight-medium text-center mb-1">Basic Package</p>
              <p class="white--text caption mb-1">Paket influencer khusus untuk small atau mini campaign</p>
              <v-btn color="white" class="subtitle-2 text-capitalize primary--text" block >Pilih Paket</v-btn>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-sm-12">
          <v-card class="mt-0" color="text-center primary" @click="goPackageDetail('premium')">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <p class="white--text body-1 font-weight-medium text-center mb-1">Premium Package</p>
              <p class="white--text caption mb-1">Paket influencer khusus untuk large atau mega campaign</p>
              <v-btn color="white" class="subtitle-2 text-capitalize primary--text" block >Pilih Paket</v-btn>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-sm-12">
          <v-card class="mt-0" color="text-center primary" @click="goPackageDetail('custom')">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <p class="white--text body-1 font-weight-medium text-center mb-1">Custom Package</p>
              <p class="white--text caption mb-1">Brand dapat menyesuaikan paket influencer sesuai budget marketing</p>
              <v-btn color="white" class="subtitle-2 text-capitalize primary--text" block >Pilih Paket</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
      <br>
      <br>
      <p class="body-1 primary--text font-weight-bold text-capitalize">kelebihan paket influencer marketing VDC:</p>
      <p class="subtitle-2"><i class="fas fa-check-circle"></i> Anda Tidak perlu lagi meminta penawaran harga ke masing-masing influencer</p>
      <p class="subtitle-2"><i class="fas fa-check-circle"></i> Anda Tidak perlu lagi mengurus berkas kontrak kerjasama ke influencer</p>
      <p class="subtitle-2"><i class="fas fa-check-circle"></i> Anda tidak perlu lagi mengurus pembayaran ke masing-masing influencer</p>
      <p class="subtitle-2"><i class="fas fa-check-circle"></i> Anda akan mendapatkan report performance influencer disetiap campaign</p>
      <p class="subtitle-2"><i class="fas fa-check-circle"></i> Semua keperluan influencer marketing brand Anda akan VDC urusin sampai tuntas!</p>
    </div>
    <!-- DESKTOP -->
    <div v-else>
      <p class="headline text-center primary--text font-weight-bold">MAU PRODUK KAMU DI PROMOSIKAN OLEH BANYAK INFLUENCER?</p>
      <p class="text-center subtitle-1 black--text">VDC telah menyiapkan berbagai paket influencer.<br>Pilih paket yang sesuai dengan kebutuhan brand saat ini</p>
      <v-row
        justify="center"
      >
        <v-col
          class="col-md-4 col-lg-4 col-sm-12 d-flex justify-center"
        >
          <v-card class="mt-5" color="text-center primary" width="300" height="330" @click="goPackageDetail('basic')">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-avatar
                color="secondary"
                class="mt-5"
                icon
                size="50"
              >
                <!-- <v-icon
                  size="2rem"
                  color="white"
                >
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon> -->
                <i class="fas fa-star white--text"></i>
              </v-avatar>
              <p class="mt-4 white--text headline font-weight-medium text-center">Basic Package</p>
            </v-card-text>
            <v-card-text class="white--text">
              Paket influencer khusus untuk small atau mini campaign
            </v-card-text>
            <v-card-text>
              <v-btn
                color="white"
                class="mt-4 text-capitalize primary--text"
              >
                Pilih Paket
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          class="col-md-4 col-lg-4 col-sm-12 d-flex justify-center"
        >
          <v-card class="mt-5" color="text-center primary" width="300" height="330" @click="goPackageDetail('premium')">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-avatar
                color="secondary"
                class="mt-5"
                icon
                size="50"
              >
                <!-- <v-icon
                  size="2rem"
                  color="white"
                >
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon> -->
                <!-- <i class="fas fa-camera primary--text"></i> -->
                <i class="fas fa-crown white--text"></i>
              </v-avatar>
              <p class="mt-4 white--text headline font-weight-medium text-center">Premium Package</p>
            </v-card-text>
            <v-card-text class="white--text">
              Paket influencer khusus untuk large atau mega campaign
            </v-card-text>
            <v-card-text>
              <v-btn
                color="white"
                class="mt-4 text-capitalize primary--text"
              >
                Pilih Paket
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          class="col-md-4 col-lg-4 col-sm-12 d-flex justify-center"
        >
          <v-card class="mt-5" color="text-center primary" width="300" height="330" @click="goPackageDetail('custom')">
            <v-card-text class="d-flex flex-column justify-center align-center">
              <v-avatar
                color="secondary"
                class="mt-5"
                icon
                size="50"
              >
                <!-- <v-icon
                  size="2rem"
                  color="white"
                >
                  {{ icons.mdiHelpCircleOutline }}
                </v-icon> -->
                <i class="fas fa-cogs white--text"></i>
              </v-avatar>
              <p class="mt-4 white--text headline font-weight-medium text-center">Custom Package</p>
            </v-card-text>
            <v-card-text class="white--text">
              Brand dapat menyesuaikan paket influencer sesuai budget marketing
            </v-card-text>
            <v-card-text>
              <v-btn
                color="white"
                class="mt-4 text-capitalize primary--text"
              >
                Pilih Paket
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <br>
      <br>
      <p class="headline primary--text font-weight-bold text-capitalize">kelebihan paket influencer marketing VDC:</p>
      <p><i class="fas fa-check-circle"></i> Anda Tidak perlu lagi meminta penawaran harga ke masing-masing influencer</p>
      <p><i class="fas fa-check-circle"></i> Anda Tidak perlu lagi mengurus berkas kontrak kerjasama ke influencer</p>
      <p><i class="fas fa-check-circle"></i> Anda tidak perlu lagi mengurus pembayaran ke masing-masing influencer</p>
      <p><i class="fas fa-check-circle"></i> Anda akan mendapatkan report performance influencer disetiap campaign</p>
      <p><i class="fas fa-check-circle"></i> Semua keperluan influencer marketing brand Anda akan VDC urusin sampai tuntas!</p>
    </div>
    <!-- <br> -->
    <!-- <p class="primary--text text-center font-weight-bold headline text-uppercase">Semua VDC yang handle YAAA!</p> -->
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const isCardDetailsVisible = false
    const rating = ref(5)

    return {
      isCardDetailsVisible,
      rating,
    }
  },
  methods: {
    goPackageDetail(item){
      // this.$router.push('/campaign/choose-influencer'); 
      this.$router.push({name:'campaign-list-package', query: {item: item}}); 
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
}
</script>