<template>
  <v-row>
    <v-col cols="12">
        <!-- <steppers-campaign></steppers-campaign> -->
        <!-- <category-item></category-item> -->
        <!-- <package-item></package-item> -->
        <!-- <unit-influencer></unit-influencer> -->
        <!-- <daftar-influencer></daftar-influencer> -->
        <started-campaign></started-campaign>
    </v-col>
  </v-row>
</template>

<script>
import DaftarInfluencer from './components/Table.vue'
import PackageItem from './components/Packages.vue'
import CategoryItem from './components/Categories.vue'
import UnitInfluencer from './components/Unit.vue'
import SteppersCampaign from './components/Steppers.vue'
import StartedCampaign from './components/Started.vue'

export default {
  components: {
    PackageItem,
    DaftarInfluencer,
    CategoryItem,
    UnitInfluencer,
    SteppersCampaign,
    StartedCampaign
  },
  setup() {
    return {}
  },
  mounted(){

  },
  methods:{
    
  }
}
</script>
